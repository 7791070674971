<template>
  <div class="line-merch-info-page" style="height: auto">
    <div v-if="pictures.length > 0">
      <Galleria
        :value="pictures"
        :responsiveOptions="responsiveOptions"
        :numVisible="5"
        :circular="true"
        containerStyle="max-width: 500px; max-height: 500px margin: auto;"
      >
        <template #item="slotProps">
          <Image
            :src="slotProps.item.url"
            :alt="slotProps.item.url"
            :preview="true"
            imageClass="w-100 d-block"
            style="heigth: 300px; object-fit: contain"
          />
        </template>
        <template #thumbnail="slotProps">
          <img
            :src="slotProps.item.url"
            :alt="slotProps.item.url"
            style="width: 100%; display: block; width: 80px; heigth: 80px"
          />
        </template>
      </Galleria>
    </div>
    <Fieldset :legend="merchandiseInfo.name">
      <pre>{{ merchandiseInfo.description }}</pre>
    </Fieldset>
    <ul class="mt-3 list tw-text-size18 fw-bolder ms-2">
      <li>
        <span
          v-if="merchandiseInfo.sellState === 'CANPREORDER'"
          class="alert-success p-2"
          >可預購</span
        >
        <span
          v-else-if="merchandiseInfo.sellState === 'LIMITED'"
          class="alert-danger p-2"
          >限量</span
        >
        <span v-else class="alert-primary p-2">僅供現貨</span>
      </li>
      <li v-if="merchandiseInfo.startSaleQuantity">
        成團數: {{ merchandiseInfo.startSaleQuantity }}
      </li>
      <li v-if="merchandiseInfo.deadLineTime">
        收單時間:
        {{
          $methods
            .moment(merchandiseInfo.deadLineTime)
            .format("YYYY-MM-DD HH:mm:ss")
        }}
      </li>
      <li v-if="merchandiseInfo.arrivalTime">
        到貨時間:
        {{
          $methods
            .moment(merchandiseInfo.arrivalTime)
            .format("YYYY-MM-DD HH:mm:ss")
        }}
      </li>
    </ul>
  </div>
</template>

<script>
// primeVue
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import Fieldset from 'primevue/fieldset';
// api
import { lineLoginWithoutReg } from '../../../../methods/API/lineLoginWithoutReg'
// methos
import { handleImageHash2Url } from "@/methods/mixins/imageHash2Url"

export default {
  components: {Galleria, Image, Fieldset},
  data() {
    return {
      serverToken: '',
      groupLinkId: '',
      merchandiseId: '',
      responsiveOptions: [{
          breakpoint: '1024px',
          numVisible: 5
        },
        {
          breakpoint: '960px',
          numVisible: 4
        },
        {
          breakpoint: '768px',
          numVisible: 3
        },
        {
          breakpoint: '560px',
          numVisible: 1
      }],
      merchandiseInfo: {},
      pictures: [],
    }
  },
  created() {
    this.initialization()
    this.initLineSdk()
  },
  methods: {
    initialization() {
      this.serverToken = ''
      this.merchandiseId = this.$route.query.merchandiseId
      this.groupLinkId = this.$route.query.groupLinkId
    },
    async initLineSdk() {
      try {
        await liff.init({ liffId: process.env.VUE_APP_MERCHANDISE_INFO_LINE_LIFFID })
        console.log(`LIFF init, liff.isLoggedIn = ${liff.isLoggedIn()}`)
        const context = liff.getContext();
        console.log(context)
        if (context.type !== 'external') this.lineUserId = context.userId;
        console.log(`groupLinkId: ${this.groupLinkId}`)
        console.log(`lineUserId: ${this.lineUserId}`)
        this.lineLogin()
      } catch (err) {
        console.error(err)
        alert('尚未取得 Line 相關資訊')
      }
    },
    lineLogin() {
      const result = lineLoginWithoutReg(this.groupLinkId, this.lineUserId)
      result.then(res => {
        if (res.code === '200') {
          console.log(res)
          this.serverToken = res.data
          this.getMerchandiseInfo()
        }
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
      })
    },
    getMerchandiseInfo() {
      const token = this.serverToken
      const merchandiseId = this.merchandiseId
      const vm = this
      const getMerchandiseInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: token
      }
      const data = [
        {
          type: 16,
          ids: [merchandiseId],
          methods: '{getMerchandisePictureLinks}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandiseInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseInfo = res.data[0].objects[0]
            vm.sortOutMerchInfo(merchandiseInfo)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    sortOutMerchInfo(merchandiseInfo) {
      this.merchandiseInfo = merchandiseInfo
      merchandiseInfo.merchandisePictureLinks.forEach(item => {
        item.url = handleImageHash2Url(item.imageHash)
        this.pictures.push(item)
      });
    },
  },
}
</script>